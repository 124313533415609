import Axios from 'axios';
import * as React from 'react';
import { useParams } from 'react-router';
import { FullContent } from '../../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType } from '../../shared';

export const ClientLOAPage = () => {
  const { licensorId } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [profile, setProfile] = React.useState<any>(null);
  const [uploading, setUploading] = React.useState(false);
  const [deleteModalShown, setDeleteModalShown] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [file, setFile] = React.useState<File | null>(null);

  React.useEffect(() => {
    loadLOA();
  },              []);

  function loadLOA() {
    setLoading(true);
    Axios.get(`/api/clients/${licensorId}/loa`)
      .then((response) => {
        setProfile(response.data.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  }

  function uploadLOA() {
    if (!file) return;
    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);

    Axios.post(`/api/clients/${licensorId}/loa`, formData)
      .then((response) => {
        setProfile(response.data.data);
        setUploading(false);
        setFile(null);
      })
      .catch(() => setUploading(false));
  }

  function deleteLOA() {
    setDeleting(true);
    Axios.delete(`/api/clients/${licensorId}/loa`)
      .then(() => {
        setProfile({
          ...profile,
          loa_file: null,
        });
        setDeleteModalShown(false);
        setDeleting(false);
      })
      .catch(() => {
        setDeleting(false);
      });
  }

  if (loading) {
    return (
      <FullContent>
        <LoadingSpinner />
      </FullContent>
    );
  }

  const hasLOA = profile && profile.loa_file && profile.loa_file.previous_file_name;

  return (
    <FullContent breadcrumbs={[{ name: 'LOA' }]}>
      <div className="panel panel-portal">
        <div className="panel-body">
          <h3>
            <strong>Letter of Authorization (LOA)</strong>
          </h3>
          <p className="text-muted">
            Upload and manage the licensor's letter of authorization. The supported file types are PDF, DOC, and DOCX.
          </p>

          {hasLOA ? (
            <div style={{ marginBottom: 20 }}>
              <strong>Current LOA:</strong> {profile.loa_file.previous_file_name}
              <div style={{ marginTop: 10 }}>
                <a
                  className="btn btn-default btn-sm"
                  href={`/api/clients/${licensorId}/loa/download`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download LOA
                </a>
                <button
                  style={{ marginLeft: 10 }}
                  className="btn btn-danger btn-sm"
                  onClick={() => setDeleteModalShown(true)}
                >
                  Delete LOA
                </button>
              </div>
            </div>
          ) : (
            <em>No LOA uploaded.</em>
          )}

          <div style={{ marginTop: 20 }}>
            <label>Upload or Replace LOA:</label>
            <input type="file" className="form-control" onChange={handleFileChange} />
            <button
              className="btn btn-primary"
              style={{ marginTop: 10 }}
              onClick={uploadLOA}
              disabled={!file || uploading}
            >
              {uploading ? 'Uploading...' : 'Upload LOA'}
            </button>
          </div>
        </div>
      </div>

      <GatewayModal
        shown={deleteModalShown}
        type={ModalType.Danger}
        onClose={() => setDeleteModalShown(false)}
        title="Delete LOA"
      >
        <div className="modal-body">
          <p className="text-center">Are you sure you want to delete this LOA?</p>
        </div>
        <div className="modal-footer">
          <button onClick={() => setDeleteModalShown(false)} className="btn btn-default">
            Cancel
          </button>
          <button onClick={deleteLOA} className="btn btn-danger" disabled={deleting}>
            {deleting ? 'Deleting...' : 'Delete LOA'}
          </button>
        </div>
      </GatewayModal>
    </FullContent>
  );
};
