import {
  faBook,
  faBuilding,
  faClipboardList,
  faClock,
  faCogs,
  faFileAlt,
  faFileCertificate,
  faFolder,
  faGlobe,
  faHashtag,
  faLandmark,
  faList,
  faPassport,
  faPercent,
  faProjectDiagram,
  faSparkles,
  faStore,
  faStreetView,
  faTachometerFast,
  faTags,
  faUmbrella,
  faUsdCircle,
  faUserFriends,
} from '@fortawesome/pro-solid-svg-icons';
import * as React from 'react';
import { Redirect, useRouteMatch } from 'react-router';
import { Route } from 'react-router-dom';
import {
  AccountAddressPage,
  AliasFormPage,
  AliasPage,
  AudienceSegmentDetailPage,
  AudienceSegmentsPage,
  CreateAccountAddress,
  CreateItemType,
  CreateKeyword,
  DesignReviewUsersPage,
  ExpeditedDesignsPage,
  ExternalWebsitesPage,
  ItemTypesPage,
  KeywordsPage,
  MetadataPage,
  PaymentMethodsPage,
  RetailersPage,
  TaxNexusPage,
  TrademarkDetailPage,
  TrademarksPage,
  VendorProductsPage,
} from '..';
import { UserContext } from '../../../contexts';
import AngularWrapper from '../../AngularWrapper/AngularWrapper';
import ClientRoyaltySchedulesPage from '../../ClientRoyaltySchedules/ClientRoyaltySchedulesPage';
import { DesignResponseIndexPage } from '../../DesignResponses/DesignResponseIndexPage';
import { InsigniaFormPage, InsigniaIndexPage } from '../../Insignia';
import { NavItemSection, SubNavigation } from '../../Navigation';
import { BenchmarksPage } from './BenchmarksPage';
import { ClientLOAPage } from './ClientLOAPage';
import { SettingsIndexPage } from './SettingsIndexPage';
import { UpiPage } from './UpiPage';

export const SettingsContainer = () => {
  const match = useRouteMatch();
  const user = React.useContext(UserContext);
  if (!match) {
    return (<Redirect to="/" />);
  }

  let route;
  if (match.params['vendorId']) {
    route = `/vendors/${match.params['vendorId']}/settings`;

  } else if (match.params['licensorId']) {
    route = `/clients/${match.params['licensorId']}/settings`;

  } else {
    route = '/settings';
  }

  const isClient = match.params['licensorId'] || user.type === 'client';
  const isVendor = match.params['vendorId'] || user.type === 'vendor';

  const routes = [
    <Route path={`${match.path}/alias`} exact={true} component={AliasPage}/>,
    <Route path={`${match.path}/alias/new`} exact={true} component={AliasFormPage}/>,
    <Route path={`${match.path}/alias/:id`} exact={true} component={AliasFormPage}/>,
    <Route path={`${match.path}/billing`} exact={true} component={PaymentMethodsPage}/>,
    <Route path={`${match.path}/expedited-designs`} exact={true} component={ExpeditedDesignsPage}/>,
    <Route path={`${match.path}/products`} exact={true} component={VendorProductsPage}/>,
    <Route path={`${match.path}/nexus`} exact={true} component={TaxNexusPage}/>,
    <Route path={`${match.path}/web-links`} exact={true} component={ExternalWebsitesPage}/>,
    <Route path={`${match.path}/designs`} exact={true} component={DesignResponseIndexPage}/>,
    <Route path={`${match.path}/trademarks`} exact={true} component={TrademarksPage}/>,
    <Route path={`${match.path}/trademarks/:id`} exact={true} component={TrademarkDetailPage}/>,
    <Route path={`${match.path}/schedules`} exact={true} component={ClientRoyaltySchedulesPage}/>,
    <Route path={`${match.path}/brand`} exact={true} component={InsigniaIndexPage}/>,
    <Route path={`${match.path}/brand/add`} exact={true} component={InsigniaFormPage}/>,
    <Route path={`${match.path}/brand/:id/edit`} exact={true} component={InsigniaFormPage}/>,
    <Route path={`${match.path}/addresses`} exact={true} component={AccountAddressPage}/>,
    <Route path={`${match.path}/addresses/add`} exact={true} component={CreateAccountAddress}/>,
    <Route path={`${match.path}/item-types`} exact={true} component={ItemTypesPage}/>,
    <Route path={`${match.path}/item-types/add`} exact={true} component={CreateItemType}/>,
    <Route path={`${match.path}/keywords`} exact={true} component={KeywordsPage}/>,
    <Route path={`${match.path}/keywords/add`} exact={true} component={CreateKeyword}/>,
    <Route path={`${match.path}/upis`} exact={true} component={UpiPage}/>,
    <Route path={`${match.path}/audiences`} exact={true} component={AudienceSegmentsPage}/>,
    <Route path={`${match.path}/audiences/:id`} exact={true} component={AudienceSegmentDetailPage}/>,
    <Route path={`${match.path}/metadata`} exact={true} component={MetadataPage}/>,
    <Route path={`${match.path}/loa`} exact={true} component={ClientLOAPage}/>,
    <Route path={`${match.path}/benchmarks`} exact={true} component={BenchmarksPage}/>,
    <Route path={`${match.path}/retailers`} exact={true} component={RetailersPage}/>,
    <Route path={`${match.path}/design-reviewers`} exact={true} component={DesignReviewUsersPage}/>,
    <Route exact={true} path={`${match.path}/profile`} component={SettingsIndexPage}/>,
    <Route exact={true} path={match.path} component={SettingsIndexPage}/>,
    <Route path={`${match.path}/:any`} exact={true} component={AngularWrapper}/>,
    <Route path={`${match.path}/:any/:any`} exact={true} component={AngularWrapper}/>,
    <Route path={`${match.path}/:any/:any/:any`} exact={true} component={AngularWrapper}/>,
  ];

  const navLinks: NavItemSection[] = [];

  const accountLinks: NavItemSection = {
    title: '',
    links: [
      {
        isBaseRoute: true,
        name: 'Account',
        path: `${route}`,
        icon: faPassport,
      },
      {
        isBaseRoute: false,
        name: 'Users',
        path: `${route}/users`,
        icon: faUserFriends,
      },

      // {
      //   isBaseRoute: false,
      //   name: 'Insurance',
      //   path: `${route}/users`,
      //   icon: faUmbrella,
      // },

    ],
  };
  if (isVendor) {
    accountLinks.links.push(
      {
        isBaseRoute: false,
        name: 'Billing',
        path: `${route}/billing`,
        icon: faUsdCircle,
      },
      {
        isBaseRoute: false,
        name: 'Operations',
        path: `${route}/operations`,
        icon: faBuilding,
      },
      {
        isBaseRoute: false,
        name: 'History',
        path: `${route}/history`,
        icon: faBook,
      },
      {
        isBaseRoute: false,
        name: 'Insurance',
        path: `${route}/insurance`,
        icon: faUmbrella,
      },
      {
        isBaseRoute: false,
        name: 'Categories',
        path: `${route}/products`,
        icon: faTags,
      },
      {
        isBaseRoute: false,
        name: 'UPIs',
        path: `${route}/upis`,
        icon: faHashtag,
      },
      {
        isBaseRoute: false,
        name: 'Design Review',
        path: `${route}/expedited-designs`,
        icon: faClock,
      },
      {
        isBaseRoute: false,
        name: 'Taxes',
        path: `${route}/nexus`,
        icon: faLandmark,
      },
    );

  }
  if (isClient) {
    accountLinks.links.push(
      {
        isBaseRoute: false,
        name: 'Trademarks',
        path: `${route}/trademarks`,
        icon: faFileCertificate,
      },
      {
        isBaseRoute: false,
        name: 'Brand Insignia',
        path: `${route}/brand`,
        icon: faProjectDiagram,
      },
      {
        isBaseRoute: false,
        name: 'Rights & Rules',
        path: `${route}/rules`,
        icon: faFolder,
      },
      {
        isBaseRoute: false,
        name: 'Royalty Schedules',
        path: `${route}/schedules`,
        icon: faPercent,
      },
      {
        isBaseRoute: false,
        name: 'License Templates',
        path: `${route}/templates`,
        icon: faFileAlt,
      },
      {
        isBaseRoute: false,
        name: 'Design Responses',
        path: `${route}/designs`,
        icon: faClipboardList,
      },
      {
        isBaseRoute: false,
        name: 'Retailers',
        path: `${route}/retailers`,
        icon: faStore,
      },
      {
        isBaseRoute: false,
        name: 'Audiences',
        path: `${route}/audiences`,
        icon: faStreetView,
      },
    );

  }
  accountLinks.links.push({
    isBaseRoute: false,
    name: 'Web Links',
    path: `${route}/web-links`,
    icon: faGlobe,
  });
  navLinks.push(accountLinks);
  if (user.type === 'admin') {
    const adminLinks = [
      {
        isBaseRoute: false,
        name: 'Settings',
        path: `${route}/admin`,
        icon: faCogs,
      },
      {
        isBaseRoute: false,
        name: 'Metadata',
        path: `${route}/metadata`,
        icon: faList,
      },
      {
        isBaseRoute: false,
        name: 'Keywords',
        path: `${route}/keywords`,
        icon: faSparkles,
      },
    ];
    if (isClient) {
      adminLinks.push({
        isBaseRoute: false,
        name: 'Benchmarks',
        path: `${route}/benchmarks`,
        icon: faTachometerFast,
      });
      adminLinks.push({
        isBaseRoute: false,
        name: 'Letter of Auth',
        path: `${route}/loa`,
        icon: faFileCertificate,
      });
      adminLinks.push({
        isBaseRoute: false,
        name: 'Design Guidelines',
        path: `${route}/design-guidelines`,
        icon: faList,
      });
      adminLinks.push({
        isBaseRoute: false,
        name: 'Design Reviewers',
        path: `${route}/design-reviewers`,
        icon: faUserFriends,
      });
    }
    navLinks.push({
      title: 'Admin',
      links: adminLinks,
    });
  }

  return (
    <SubNavigation navTitle="Settings" navLinks={navLinks} routes={routes} />

  );
};
