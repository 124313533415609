import { faAlarmExclamation, faFilter, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios, { CancelTokenSource } from 'axios';
import { debounce, isEqual } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { AffinityImage, Design } from '../../shared';
import User from '../../shared/User';
import { getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { FilterBar, LoadingSpinner, PaginationFooter, ReviewGridItem, StatusFilterBar, UserTypeahead } from '../shared';
import { AdvancedDesignFiltersModal } from './AdvancedDesignsFiltersModal';
import { getCounts, getDesigns } from './api';
import { DesignFilters } from './DesignFilters';
import { DesignUploadPanel } from './DesignUploadPanel';

export const DesignsIndexPage = () => {

  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const user = React.useContext(UserContext);
  const queryValues = queryString.parse(location.search);
  const debounceFilter = React.useRef(debounce((f: DesignFilters) => performUpdates(f), 1000));
  const [filters, setFilters] = React.useState(new DesignFilters(match, location.search, user && user.type ? user.type : ''));
  const [prevFilters, setPrevFilters] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(true);
  const [designs, setDesigns] = React.useState<Design[]>([]);
  const [counts, setCounts] = React.useState<any[]>([]);
  const [totalDesigns, setTotalDesigns] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [hasMorePages, setHasMorePages] = React.useState(false);
  const [reviewers, setReviewers] = React.useState<User[]>(queryValues.reviewer ? [new User({ id: Number(queryValues.reviewer), first_name: 'Loading...', last_name: '' })] : []);
  const [advancedSearch, setAdvancedSearch] = React.useState(false);
  const [designsCancelToken, setDesignsCancelToken] = React.useState<CancelTokenSource | null>(null);
  const [designsCountCancelToken, setDesignsCountCancelToken] = React.useState<CancelTokenSource | null>(null);

  React.useEffect(() => {
    getDesignList();
    getDesignCounts();

  },              [location]);

  React.useEffect(() => {

  },              [advancedSearch]);

  if (!match)  {
    return <Redirect to="/" />;
  }

  const vendorId = match.params['vendorId'];

  const isVendorFiltered = match.params['vendorId'] ? true : false;
  const isClientFiltered = match.params['licensorId'] ? true : false;

  const getDesignList = async () => {
    if (designsCancelToken) {
      designsCancelToken.cancel('Cancelled getDesigns() XHR due to new request.');
    }
    const token = Axios.CancelToken.source();
    setDesignsCancelToken(token);
    setLoading(true);
    const params = filters.generateAPIParams();
    const d =  await getDesigns(params, token);
    if (d && d.data) {
      setDesigns(d.data.data.map((design: any) => Design.fromApi(design)));
      if (d.data.meta) {
        setHasMorePages(d.data.meta.pagination.has_more);
      }
    } else {
      setDesigns([]);
    }
    setLoading(false);
    setDesignsCancelToken(null);
  };

  const getDesignCounts = async () => {
    const params = filters.generateAPIParams();
    let shouldContinue = false;
    if (prevFilters) {
      const paramCopy = { ...params };
      const prevCopy = { ...prevFilters };
      // ignored params
      delete paramCopy.page;
      delete paramCopy.phase_group;
      delete prevCopy.page;
      delete prevCopy.phase_group;
      const paramQS = queryString.stringify(paramCopy);
      const prevQS = queryString.stringify(prevCopy);
      shouldContinue = paramQS !== prevQS;
    } else {
      shouldContinue = true;
    }
    if (shouldContinue) {
      if (designsCountCancelToken) {
        designsCountCancelToken.cancel('Cancelled getDesignCounts() XHR due to new request.');
      }
      const token = Axios.CancelToken.source();
      setDesignsCountCancelToken(token);
      setPrevFilters(params);
      const c = await getCounts(params, token);
      if (c && c.data) {
        const cl = c.data.data.map((count: any) => mapCount(count));
        setCounts(cl);
        const currentCount = findCount(cl, filters.status);
        setTotalPages(Math.ceil(currentCount / filters.perPage));
        setTotalDesigns(currentCount);
      }
      setDesignsCountCancelToken(null);
    }
  };

  const mapCount = (count: any) => {
    const l = {
      id: count.id,
      count: count.count,
      alertClass: '',
      name: count.group,
    };
    switch (l.id) {
      case 1:
        l.alertClass = 'success';
        break;
      case 2:
        l.alertClass = 'info';
        break;
      case 3:
        l.alertClass = 'purple';
        break;
      case 4:
        l.alertClass = 'danger';
        break;
      case 5:
        l.alertClass = 'default';
        break;
      default:
        l.alertClass = 'default';
    }
    if (l.id === null) {
      l.id = 0;
    }
    return l;
  };

  const findCount = (countList: any[], group: number) => {
    const c = countList.find(g => group === g.id);
    if (c) {
      return c.count;
    }
    return 0;

  };

  const performUpdates = (f: DesignFilters) => {
    setQueryParams(f);
  };
  const filter = (values: any, debounce: boolean) => {
    const nf = copyFilters();
    nf.setFilters(filters.filters);
    if (values.search !== undefined) {
      nf.search  =  values.search;
      setFilters(nf);
    } else {
      nf.page = 1;
      nf.setFilters(values);
      setFilters(nf);
      if (debounce) {
        debounceFilter.current(nf);
      } else {

        performUpdates(nf);
      }

    }
  };
  const setStatus = (status: any) => {
    const nf = copyFilters();
    nf.setFilters(filters.filters);
    nf.status = status;
    nf.page = 1;
    setFilters(nf);
    setQueryParams(nf);
  };

  const setPage = (page: number) => {
    const nf = copyFilters();
    nf.setFilters(filters.filters);
    nf.page = page;
    setFilters(nf);
    setQueryParams(nf);
  };

  const copyFilters = () => {
    const nf = new DesignFilters(match, location.search, user && user.type ? user.type : '');
    nf.setFilters(filters.filters);
    return nf;
  };

  const setQueryParams = (f: DesignFilters) => {
    const qs = f.generateQueryParamString();
    history.push(`${location.pathname}?${qs}`);
  };

  const qp = filters.generateQueryParamString();

  const backupImage = new AffinityImage();
  backupImage.defaultImage = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/vendor-icon.png';

  const d = designs.map(design => (
    <div key={design.id} className="col-xl-2 col-lg-3 col-md-4 col-sm-4">
      <ReviewGridItem
        status={design.status}
        url={`/designs/${design.id}?${qp}`}
        image={design.primaryIteration && design.primaryIteration.image ? design.primaryIteration.image.getSize('sm') : backupImage.getSize('sm')}
        statusIconElement={design.isExpedited && design.isPending ? (
          <OverlayTrigger
            trigger="hover"
            placement="top"
            overlay={(
              <Tooltip id={`expedited-${design.id}`}>
                <span>Time Sensitive</span>
              </Tooltip>
            )}

          >
            <span><FontAwesomeIcon icon={faAlarmExclamation} /></span>
          </OverlayTrigger>
        ) : undefined}
      >
        <div style={{ paddingTop: 0, textAlign: 'center' }} className="panel-body">
          <div style={{ fontSize: 12 }} className=" text-ellipsis text-primary">
            {design.primaryClient && design.primaryClient.id ? design.primaryClient.shortName : 'Licensor Unknown'}
          </div>
          <div style={{ fontSize: 14, fontWeight: 'bold' }} className=" text-primary text-ellipsis">
            {design.title}
          </div>

          <div style={{ fontSize: 12 }} className=" text-ellipsis">
            {design.vendor.shortName}
          </div>
          <div style={{ fontSize: 12 }} className="text-muted text-ellipsis">
            {design.isResubmission ? 'Resubmission' : 'First Submission'}

          </div>

        </div>

      </ReviewGridItem>

    </div>
  ));
  const body = loading ? (<LoadingSpinner />) : (
    <div className="row">
      {d.length ? d : <NoDesignsPanel filters={filters} />}
    </div>
  );

  return (
    <DocumentTitle title={getAffinityPageTitle('Designs')}>
      <FullContent>
        <div className="page-title">
          <h4>Product Designs</h4>
          <div className="page-title-actions">
          </div>
        </div>
        {user.type === 'vendor' || (user.type === 'admin' && vendorId) ?
          <DesignUploadPanel
            hasPermissions={user.canUploadDesigns}
            isCurrent={user.account.status.display === 'Current'}
            canMassUpload={user.canMassUploadDesigns}
            includeButton={true}
            vendorId={vendorId}
          />

          : null}

        <StatusFilterBar
          labels={counts}
          selected={filters.status !== null ? filters.status : null}
          selectFn={setStatus}
        />
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          <FilterBar
            useSearch={true}
            disableSearch={user.type === 'admin' && !(isClientFiltered || isVendorFiltered)}
            search={filters.search}
            usePerPage={false}
            perPage={filters.perPage}
            useQuarter={false}
            useDates={true}
            startDate={filters.startDate}
            endDate={filters.endDate}
            updateFilters={v => filter(v, true)}
            fieldClassOverrides={{ search: 'col-sm-2 col-md-2 col-lg-2 col-xs-12', perPage: 'col-sm-1 col-md-1 col-lg-1 col-xs-12' }}
            useAdvancedSearch={false}
            isFiltered={filters.isFiltered}
          >
            <div className="col-lg-2 col-sm-2 col-xs-12">
              <select
                onChange={event => filter({ queue: Number(event.target.value) }, true)}
                value={filters.queue}
                className="form-control input-sm"
              >
                <option value={0}>Queue Filter (All)</option>
                <option value={1}>Time Sensitive Review</option>
                <option value={2}>Standard Review</option>
              </select>

            </div>

            {user.type === 'admin' ? (
              <div className="col-lg-2 col-sm-2 col-xs-12">
                <UserTypeahead
                  onChange={(r) => {
                    const onlyId = r.map(r => Number(r.id));
                    const stateOnlyId = reviewers.map(r => Number(r.id));
                    if (!isEqual(onlyId, stateOnlyId)) {
                      filter({ reviewers: r }, true);
                    }
                    setReviewers(r);

                  }}
                  selected={reviewers}
                  multiple={false}
                  accountId={1}
                  size={'sm'}
                  placeholder="Reviewers"

                />

              </div>

            ) : null}
            <div className="col-lg-2 col-sm-2 col-xs-12">
              <button onClick={() => setAdvancedSearch(true)} className="btn btn-sm btn-default">
                <FontAwesomeIcon icon={faFilter} /> Filters
              </button>

            </div>

          </FilterBar>

        </div>
        {body}
        {!loading ? <PaginationFooter
          totalResults={totalDesigns}
          hasNext={hasMorePages}
          currentPage={filters.page}
          setPage={setPage}
          totalPages={totalPages}
          showFirstLast={true}
        /> : null}
        <AdvancedDesignFiltersModal filter={v => filter(v, false)} shown={advancedSearch} onClose={() => setAdvancedSearch(false)} filters={filters} />

      </FullContent>
    </DocumentTitle>

  );

};

interface NoDesignsProps {
  filters: DesignFilters;
}

const NoDesignsPanel = (props: NoDesignsProps) => {

  let text;
  switch (Number(props.filters.status)) {
    case 1 :
      text = 'No approved designs';
      break;
    case 2 :
      text = 'No designs in review';
      break;
    case 3:
      text = 'No designs in review';
      break;
    case 4:
      text = 'No rejected designs';
      break;
    case 5:
      text = 'No designs on hold';
      break;

    default:
      text = 'No designs';
  }

  return (
    <div className="no-designs-panel text-muted text-center">
      <div className="no-designs-icon">
        <FontAwesomeIcon icon={faSearch} />
      </div>
      <h3>{text}</h3>

    </div>
  );

};
